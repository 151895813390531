<template>
    <div class="Brand" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleSelectSku"
                size="small"
                v-if="hasPrivilege('menu.sku.offline.banned.create')"
            >
                添加禁售商品
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column prop="name" label="名称" width="180" />
                <el-table-column prop="bars" label="条码" width="120">
                    <template slot-scope="scope">
                        <span v-for="b in scope.row.bars" :key="b">{{ b }}<br /></span>
                    </template>
                </el-table-column>
                <el-table-column prop="specs" label="规格" width="120" />
                <el-table-column prop="type" label="类型" width="80">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type ? '非标品' : '标品' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="category" label="类目" width="120" />
                <el-table-column prop="brand" label="品牌" width="120" />
                <el-table-column prop="unit" label="单位(小)" width="80" />
                <el-table-column prop="expirationDate" label="保质期" width="100" />
                <el-table-column prop="creator" label="创建人" width="120" />
                <el-table-column label="操作" header-align="center" width="120">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.sku.offline.banned.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <pickupSku ref="pickupSku" @ok="handleSelectSkuOk" />
    </div>
</template>

<script>
import Util from '../../../js/Util';
import PickupSku from '../../components/PickupSkuBanned';

import qs from 'qs';

export default {
    name: 'Forbid',
    components: { PickupSku },
    data() {
        return {
            form: {
                banStatus: 1,
                page: 1,
                limit: Util.Limit,
            },
            dialogTableVisible: false,
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/goods/sku/pageExtendBanProd',
                delete: '/goods/brand/delete',
                addAndDelBanProd: '/goods/sku/addAndDelBanProd',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.goods.brand.create', ['商品管理', '品牌管理', '新建品牌']);
        },
        handleDelete(code) {
            const codeList = [code];
            const _params = { codeList: codeList, addOrDel: 0 };
            this.handleAddOrDel(_params);
        },
        handleSelectSku() {
            //配送中心机构类型只能查询标品商品
            this.$refs.pickupSku.show();
        },
        handleSelectSkuOk(data) {
            const codeList = data.map((item) => {
                return item.code;
            });
            const _params = { codeList: codeList, addOrDel: 1 };
            if (codeList) {
                this.handleAddOrDel(_params);
            }
        },
        handleAddOrDel(params) {
            const _this = this;
            const config = {};
            const headers = config['header'] || {};
            headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
            config.headers = headers;
            Object.assign(config, {
                transformRequest: [
                    function (data) {
                        return qs.stringify(data, { indices: false });
                    },
                ],
            });
            this.$http.post(_this.url.addAndDelBanProd, params, config).then((rst) => {
                if (rst['status'] === 200 && rst['data'].status === 200) {
                    _this.$message.success('操作成功');
                    _this.handleQuery();
                }
            });
        },
    },
};
</script>

<style scoped>
.Brand .el-form-item {
    margin-bottom: 0;
}
</style>
